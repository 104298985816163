<template>
  <el-header>
    <el-row class="header-wrapper">
      <div class="header-brand">      
        <div class="header-brand-left">
          <img @click="fhlmLink" class="header-logo" src="@/assets/images/logo.png" alt="">
        </div>
      </div>      
      <div class="header-menu">
        <router-link to="/">首页</router-link>
        <router-link to="/embed">嵌入</router-link>
        <router-link to="/about">关于</router-link>
      </div>
    </el-row>    
  </el-header>
</template>
<script>
export default {
  data(){
    return{
      isType: ''
    }
  },
  mounted(){
  },
  methods: {   
    fhlmLink(){
      window.open('https://www.fhlm33.com/')
    },
  }  
}
</script>
<style lang="sass" scoped>
.header-wrapper
  max-width: 1200px
  height: 60px
  margin-right: auto
  margin-left: auto  
  background: #E41229
  color: #FFFFFF
  display: flex
  justify-content: space-between
  align-items: center
  .header-brand
    width: 100%
    .header-brand-left
      display: flex
      align-items: center
      .header-logo
        cursor: pointer
      .header-title
        font-size: 20px
        padding-left: 20px
  .header-menu
    width: 100%
    display: flex
    justify-content: space-evenly
    a
      font-family: Noto Sans SC
      font-size: 18px
      color: rgba(255, 255, 255, 0.6)
      text-decoration: none
    .router-link-exact-active
      color: #eee
.coverForm
  overflow-y: hidden      
</style>
