<template>
  <el-container>
    <v-header v-if="isShow"></v-header>
    <el-main>
      <router-view/>
    </el-main>
    <v-footer></v-footer>
    <p class="footer-btm">Copyright © 2021 Phoenix Union Inc.   凤凰联盟 www.fhlm.com 版权所有</p>
  </el-container>
</template>
<script>
import Header from '../../components/Proxy/Header'
import Footer from '../../components/Proxy/Footer'
export default {
  components: {
    'v-header': Header,
    'v-footer': Footer,
  },  
  data(){
    return{
      isShow: Boolean,
    }
  },
  mounted(){
    if(this.$route.query.header == 'false'){
      this.isShow = false
    }else{
      this.isShow = true
    }    
  }  
}
</script>
<style scoped>
  .el-container{
    display: block;
    background: #F2F2F2;
  }
  .el-header {
    background-color: #E41229;
    color: #333;
    line-height: 60px;
  }
  .el-footer {
    background-color: #E41229;
    height: auto !important
  }  
  .el-main {
    background-color: #F2F2F2;
    color: #333;
    padding: 0;
  }
  .footer-btm {
    background: #CE1025;
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #FFFFFF;
    text-align: center;
    padding: 15px;
  }
</style>
