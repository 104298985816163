<template>
  <el-footer>
    <el-row class="footer-wrapper">
      <div class="footer-left">
        <div class="footer-menu">
          <p><router-link to="/">首页</router-link></p>
          <p class="footer-menu-embed"><router-link to="/embed">嵌入</router-link></p>
          <p><router-link to="/about">关于</router-link></p>
        </div>        
      </div>
      <div class="footer-right">
        <div class="mobile-line">
          <p class="title">联系我们</p>
          <p class="contact-type"><img src="@/assets/images/telegram.png" alt=""><a href="https://t.me/ZAK_FHLM" target="_blank">@ZAK_FHLM</a></p>
          <p class="contact-type"><img src="@/assets/images/skype.png" alt=""><a href="https://join.skype.com/invite/V15QpHpQPrOX" target="_blank">live:.cid.7f3ec0b63abc7df9</a></p>
          <p class="contact-type"><img src="@/assets/images/qq.png" alt=""><a>3175501  13635698</a></p>
        </div>
        <div>
          <p class="title">合作伙伴</p>
          <p>
            <!-- <img class="img-logo" src="@/assets/images/fhlm-logo.png" alt="">
            <img class="img-boniu" src="@/assets/images/boniu.png" alt=""> -->
            <img class="img-hznb" src="@/assets/images/hzhb.png" alt="">
          </p>
          <!-- <p>FHLM © 2021</p> -->
        </div>
      </div>
    </el-row>
  </el-footer>
</template>
<script>
export default {
  data(){
    return{
    }
  },
  methods: {
  }
}
</script>
<style lang="sass" scoped>
  .footer-wrapper
    max-width: 1200px
    background-color: #E41229
    margin-right: auto
    margin-left: auto
    padding: 30px 0
    display: flex
    justify-content: space-between
    .footer-left,.footer-right
      width: 100%
      p 
        font-family: PingFangSC-Semibold
        font-size: 18px
        color: #FFFFFF
      a
        text-decoration: none
        font-family: PingFangSC-Regular
        font-size: 14px
        line-height: 24px
        color: #fff
    .footer-menu
      display: flex
      a
        font-family: PingFangSC-Semibold
        font-size: 18px
        color: #FFFFFF
      .footer-menu-embed
        margin: 0 20px        
    .footer-right
      display: flex
      justify-content: space-between
      .title
        margin-bottom: 10px
      .contact-type
        display: flex
        align-items: center
        img 
          margin-right: 10px
    .img-logo
      width: 124px
    .img-boniu
      width: 72px
      margin-left: 50px
    .img-hznb
      width: 250px
  @media screen and (max-width: 767px)
    .footer-wrapper
      display: block
      padding: 20px 10px
      .footer-right
        display: block
      .mobile-line
        padding: 20px 0
        margin: 20px 0
        border-top: 1px solid #FFFFFF
        border-bottom: 1px solid #FFFFFF
  @media screen and (max-width: 350px)
    .footer-wrapper
      padding: 20px 0px
      .footer-left,.footer-right
        p,a 
         font-size: 4vw
</style>